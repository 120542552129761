import React, {useEffect, useState} from "react";
import {Button, Card, Collapse, Form, Input, List} from "antd";
import axios from 'axios'
import { NavLink } from "react-router-dom";

const {Panel} = Collapse;


const Klient = () => {
    const price_one = [
        {rad: 13,
            price: 1000},
        {
            rad: 14,
            price: 1200
        },
        {
            rad: 15,
            price: 1600
        },{
            rad: 16,
            price: 1800
        },{
            rad: 17,
            price: 2000
        },{
            rad: 18,
            price: 2200
        },{
            rad: 19,
            price: 2400
        },{
            rad: 20,
            price: 3000
        },
    ]
    const price_two = [
        {rad: 13,
            price: 2400},
        {
            rad: 14,
            price: 2400
        },
        {
            rad: 15,
            price: 2400
        },{
            rad: 16,
            price: 2600
        },{
            rad: 17,
            price: 2600
        },{
            rad: 18,
            price: 2800
        },{
            rad: 19,
            price: 2800
        },{
            rad: 20,
            price: 5000
        },
    ]
    const now = new Date()
    const data = [
        {
            title: 'метро Бауманская',
            add: 'Спартковская площад дом 1/7 стр 1'
        },
        {
            title: 'метро Бельяево',
            add: 'Москва улица Академика Опарино 7'
        },
        {
            title: 'метро Владыкино',
            add: 'Москва Алтуфьевское шоссе д 27 стр 1'
        },
        {
            title: 'метро Сходненская',
            add: 'Москва проезд Донейлайтиса 12 к2'
        },
        {
            title: 'метро Кантемировская',
            add: 'Москва, ул Кантемировская д 29 к 1'
        }
    ];
    const [state, setState] = useState([])
    const [update, setUpdate] = useState(true)
    const [modal, setModal] = useState(false)
    const [logi, setLogi] = useState({name: 'zebra', vis: true, err: false})

    useEffect(() => {
        window.scrollTo(0, 0)
        if (localStorage.getItem("name") === "zebra") {
            setLogi({vis: false, err: false, name: 'zebra'})
        } else {
            setLogi({vis: true, err: true, name: 'zebra'})
        }
    }, [])
    useEffect(() => {
        axios.get('https://rshakh.ru:3002').then(promise => {
            if (!state === promise.data) {

            } else {
                setState(promise.data)
            }
        }).catch(e => {
            setModal(true)
        })
    }, [update])
    setTimeout(() => {
        setUpdate(!update)
    }, 60000)
    const onFinish = (e) => {
        if (e.name === logi.name) {
            setLogi({vis: false, err: false, name: 'zebra'})
            localStorage.setItem("zebra", e.name)
        } else {
            setLogi({vis: true, err: true, name: 'zebra'})
        }
    }
    console.log(state)
    const NowKlient = (state) => {
        state.map(el => {
            let se = now.getMonth()
            let da = now.getDate()
            let dt = el.data.split("-")
            if (da == dt[0] && se + 1 == dt[1]) {
                console.log(el.info.length)
                return (
                    <div>
                        <h2>Всего записано на сегодня {el.info.length}</h2>
                    </div>
                )
            }
        })
    }

    return (
        <Card title='Список клиентов'>
            {!logi.vis ? <div>{modal ? <div><h2>Ошибка позвоните</h2>
                    <h2><a href="tel:+79299977923">+79299977923</a></h2></div>
                : <div>
                    <>
                        <div>
                            {
                                state.map(el => {
                                    let se = now.getMonth()
                                    let da = now.getDate()
                                    let dt = el.data.split("-")
                                    if (da == dt[0] && se + 1 == dt[1]) {
                                        console.log(el.info.length)
                                        return (
                                            <div>
                                                <h2>Всего записано на сегодня {el.info.length}</h2>
                                                {el.info.map(kl => {
                                                    return (
                                                        <div>
                                                            {
                                                                data.map(add => {
                                                                    if (add == kl.address) {

                                                                    }
                                                                })
                                                            }
                                                        </div>

                                                    )

                                                })}
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                        <div className='klientborder'>
                            <div>
                                <Collapse>
                                    {data.map(el => {
                                        return (
                                            <Panel header={el.add}>
                                                {state.map(data => {
                                                    const arr = data.info.filter(fil => fil.address === el.add)
                                                    return (
                                                        <Collapse>{arr.length !== 0 &&
                                                            <Panel key={data.data}
                                                                   header={data.data + ' Всего заказов ' + arr.length}>
                                                                {data.info.map(info => {
                                                                    if (info.address === el.add) {

                                                                        return (
                                                                            <div>
                                                                                <div>
                                                                                    <div>{info.address}</div>
                                                                                    <div>{info.service}</div>
                                                                                    <div>{info.name}</div>
                                                                                    <div><a
                                                                                        href={`tel:${info.tel}`}>{info.tel}</a>
                                                                                    </div>
                                                                                    <div className='klientbor'><span
                                                                                        style={{marginRight: '10px'}}>{info.data}</span><span>{info.time}</span>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    }
                                                                })}
                                                            </Panel>
                                                        }
                                                        </Collapse>
                                                    )
                                                })}
                                            </Panel>
                                        )
                                    })}
                                </Collapse>
                            </div>
                        </div>
                    </>
                    <Button><NavLink to="/editprice">Редактировать прайслист</NavLink></Button>
                    <Button><NavLink to="/editaddress">Редактировать адресов</NavLink></Button>
                </div>}</div> : <div><Form
                name="basic"
                onFinish={onFinish}
            >
                <Form.Item
                    label="Ваша имя"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Введите фразу',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Проверить
                    </Button>
                </Form.Item>
            </Form></div>}
            {logi.err && <div style={{color: "red"}}>Ошибка</div>}
            <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}
                 id="yandex_rtb_R-A-581561-1"></div>

        </Card>
    )
}
export default Klient
/*<div>
<div>{info.address}</div>
<div>{info.service}</div>
<div>{info.name}</div>
<div><a href={`tel:${info.tel}`}>{info.tel}</a></div>
<div className='klientbor'><span style={{marginRight:'10px'}}>{info.data}</span><span>{info.time}</span></div>
</div>*/