import React from "react";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import poly1 from '../../media/img/poly/2.jpg'
import poly2 from '../../media/img/poly/3.jpg'
import poly3 from '../../media/img/poly/4.jpg'
import poly4 from '../../media/img/poly/5.jpg'
import poly5 from '../../media/img/poly/6.jpg'
import poly6 from '../../media/img/poly/7.jpg'
import poly7 from '../../media/img/poly/8.jpg'
import poly8 from '../../media/img/poly/9.jpg'
import s from './style.module.css'
import Usligu from "../Uslugi/Uslugi";

const PolyGallery = () => {
    return(
        <>
        <div style={{marginTop:'40px',marginBottom:'40px'}} className={s.khiminner}>
            <h2>Полировка Авто</h2>
            <AwesomeSlider className={s.khim}>
                <div data-src={poly1}/>
                <div data-src={poly2}/>
                <div data-src={poly3}/>
                <div data-src={poly4}/>
                <div data-src={poly5}/>
                <div data-src={poly6}/>
                <div data-src={poly7}/>
                <div data-src={poly8}/>
            </AwesomeSlider>
        </div>
    <Usligu/></>
    )
}
export default PolyGallery