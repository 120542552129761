import React, {useEffect, useState} from "react";
import {Button, Spin} from "antd";
import axios from 'axios'
import https from "https";

const Time=(props)=>{
    const [tim,setTime]=useState([])
    const times=[]
    const zapis=[]

    const [loadindg,setLoading]=useState(true)
    useEffect(()=>{
        axios.get('https://rshakh.ru:3002').then((promise)=>{
            setLoading(false)
                for (let i = 9; i <= 23; ++i) {
                    times.push(`${i}:00`)
                    for (let j = 30; j <= 30; j++) {
                        times.push(`${i}:${j}`)
                    }
                }


              /*else  for (let i = 9; i <= 23; ++i) {
                    times.push(`${i}:00`)
                    for (let j = 20; j <= 20; j++) {
                        times.push(`${i}:${j}`)
                    }
                    for (let z = 40; z <= 40; z++) {
                        times.push(`${i}:${z}`)
                    }
                }*/


            setTime(times)
            promise.data.map(el=>{
               if (el.data===props.data){
                   el.info.map(info=>{
                       if (info.service===props.service && info.address === props.address){
                           zapis.push(info.time)
                       }
                   })
               }else {

               }
            })
            if (zapis.length>0){
                const arr3 = times.filter(e=>zapis.findIndex(i=>i == e) === -1);
                setTime(arr3)
            }
            }
        ).catch(e=>{
            props.setEmodal(true)
        })
        }
    ,[])

        const onTime=(el)=>{
            props.setTime({time:el,vis:false})
        }

    return(
        <div style={{display:'flex',flexWrap:'wrap',overflow:'scroll',height:'300px',justifyContent:'center'}}>
            {loadindg?<Spin style={{marginTop:'50px'}}/> : <>{tim.map(el=><>
                <div style={{width:'30%',margin:'5px'}}>
                   <Button onClick={()=>onTime(el)} style={{width:'80px'}} type='primary'>{el}</Button>
                </div>
            </>)}</>}
        </div>
    )
}
export default Time