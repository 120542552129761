import React, {useEffect, useState} from "react";
import {Button, Card} from "antd";
import {NavLink} from "react-router-dom";
import s from './style.module.css'
import shin from "../../media/img/shin.png"
import Skidki from "../skidki/skidki";
import axios from "axios"

const Price=()=>{
    const price_kro = [
        {rad: 13,
            price: 2200},
        {
            rad: 14,
            price: 2200
        },
        {
            rad: 15,
            price: 2200
        },{
            rad: 16,
            price: 2800
        },{
            rad: 17,
            price: 3200
        },{
            rad: 18,
            price: 3400
        },{
            rad: 19,
            price: 3600
        },{
            rad: 20,
            price: 4200
        },
        {
            rad: 21,
            price: 5000
        },
    ]
    const price_two = [
        {rad: 13,
            price: 1500},
        {
            rad: 14,
            price: 1500
        },
        {
            rad: 15,
            price: 2200        },{
            rad: 16,
            price: 2400
        },{
            rad: 17,
            price: 2600
        },{
            rad: 18,
            price: 2800
        },{
            rad: 19,
            price: 3000
        },{
            rad: 20,
            price: 3800
        },
        {
            rad: 21,
            price: 4400
        },
    ]
    const [price,setPrice] =useState()
    const [loading,setLoading] =useState({loading:false})
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('https://rshakh.ru:3001/api/avtostol/price').then(({data})=>{
            setPrice(data)
            setLoading({loading: false})
        })
    }, [])
    return(
        <>
            <>{loading.loading ? <> loading</> :<Card className={s.text} title='Цены на шиномонтаж легковые авто'>
                <div className={s.shin}>
        
                
                {
                        price_two.map(el=>{
                            return<div className={s.r13}> 
                                <h2><span className={s.red}>Dia</span>metr R{el.rad}</h2>
                                <div className={s.r13_inner}>
                                    <span><img src={shin} alt="шиномонтаж москва"/>x4 </span>
                                    <h3>{el.price} рублей</h3>
                                </div>
                            </div>
                        })
                    }
                    

                    <div>
                        <p>
                            Дополнительно оплачивается на месте: — шиномонтаж низкопрофильной резины (профиль 50 и ниже) и шин RunFlаt — 150 руб./1 колесо; — шиномонтаж колес с датчиками давления — 150 руб./1 колесо. Дополнительные услуги, которые можно приобрести при необходимости: — упаковка колес в пакеты — 100 руб./4 шт.; — обработка колесных ступиц медной смазкой — 300 руб./4 ступицы; — герметизация бортов — 50 руб./1 колесо; — чистка дисков — 200 руб./4 колеса; — бесконтактная мойка, чистка колес — 50 руб./1 колесо; — замена вентилей — 320 руб./4 колеса.
                        </p>
                    </div>
                </div>

                <div style={{marginTop:'20px',marginBottom:'10px'}}><Button type='primary'><NavLink to='/onlinez'>Записатся онлайн</NavLink></Button></div>

        </Card>}
                </>
            <Card className={s.text} title='Цены на шиномонтаж кроссоверы'>
                <div className={s.shin}>
                    
                    
                    
                    {
                        price_kro.map(el=>{
                            return<div className={s.r13}>
                                <h2><span className={s.red}>Dia</span>metr R{el.rad}</h2>
                                <div className={s.r13_inner}>
                                    <span><img src={shin} alt="шиномонтаж москва"/>x4 </span>
                                    <h3>{el.price} рублей</h3>
                                </div>
                            </div>
                        })
                    }

                    <div>
                        Дополнительно оплачивается на месте: — шиномонтаж низкопрофильной резины (профиль 50 и ниже) и шин RunFlаt — 150 руб./1 колесо; — шиномонтаж колес с датчиками давления — 150 руб./1 колесо. Дополнительные услуги, которые можно приобрести при необходимости: — упаковка колес в пакеты — 100 руб./4 шт.; — обработка колесных ступиц медной смазкой — 300 руб./4 ступицы; — герметизация бортов — 50 руб./1 колесо; — чистка дисков — 200 руб./4 колеса; — бесконтактная мойка, чистка колес — 50 руб./1 колесо; — замена вентилей — 320 руб./4 колеса.
                    </div>
                </div>

                <div style={{marginTop:'20px',marginBottom:'10px'}}><Button type='primary'><NavLink to='/onlinez'>Записатся онлайн</NavLink></Button></div>

        </Card>
            <Card className={s.text} title='Цены на хранение шин'>
                <div className={s.shin}>
                    <div className={s.r13}>
                        <h2><span className={s.red}>Dia</span>metr R13</h2>
                        <div className={s.r13_inner}>
                            <span><img src={shin} alt="хранение шин москва"/>x4 </span>
                            <h3>3000 рублей на 6-месяцев</h3>
                        </div>
                    </div><div className={s.r13}>
                    <h2><span className={s.red}>Dia</span>metr R14</h2>
                    <div className={s.r13_inner}>
                        <span><img src={shin} alt="хранение шин москва"/>x4 </span>
                        <h3>3000 рублей на 6-месяцев</h3>
                    </div>
                </div><div className={s.r13}>
                    <h2><span className={s.red}>Dia</span>metr R15</h2>
                    <div className={s.r13_inner}>
                        <span><img src={shin} alt="хранение шин москва"/>x4 </span>
                        <h3>3000 рублей на 6-месяцев</h3>
                    </div>
                </div><div className={s.r13}>
                    <h2><span className={s.red}>Dia</span>metr R16</h2>
                    <div className={s.r13_inner}>
                        <span><img src={shin} alt="хранение шин москва"/>x4 </span>
                        <h3>3000 рублей на 6-месяцев</h3>
                    </div>
                </div><div className={s.r13}>
                    <h2><span className={s.red}>Dia</span>metr R17</h2>
                    <div className={s.r13_inner}>
                        <span><img src={shin} alt="хранение шин москва"/>x4 </span>
                        <h3>3000 рублей на 6-месяцев</h3>
                    </div>
                </div><div className={s.r13}>
                    <h2><span className={s.red}>Dia</span>metr R18</h2>
                    <div className={s.r13_inner}>
                        <span><img src={shin} alt="хранение шин москва"/>x4 </span>
                        <h3>3000 рублей на 6-месяцев</h3>
                    </div>
                </div><div className={s.r13}>
                    <h2><span className={s.red}>Dia</span>metr R19</h2>
                    <div className={s.r13_inner}>
                        <span><img src={shin} alt="хранение шин москва"/>x4 </span>
                        <h3>3000 рублей на 6-месяцев</h3>
                    </div>
                </div><div className={s.r13}>
                    <h2><span className={s.red}>Dia</span>metr R20+1+2+</h2>
                    <div className={s.r13_inner}>
                        <span><img src={shin} alt="хранение шин москва"/>x4 </span>
                        <h3>3000 рублей на 6-месяцев</h3>
                    </div>
                </div>
                </div>

                <div style={{marginTop:'20px',marginBottom:'10px'}}><Button type='primary'><NavLink to='/onlinez'>Записатся онлайн</NavLink></Button></div>

            </Card>
    <Card title='Цены Химчистки'>
        На полную химчистку:<br/>
       1 категория - 7950<br/>
       2 категория - 8490<br/>
       3 категория - 9990<br/>
       4 категория - 11490<br/>
       Премиум-химчистка: <br/>
       1 категория - 8990 <br/>
       2 категория - 9490<br/>
       3 категория - 11 990<br/>
       4 категория - 12 490<br/>
        В стоимость на полную химчистку автомобиля входит:<br/>
        — химчистка пола;<br/>
        — химчистка всех сидений;<br/>
        — химчистка панели приборов;<br/>
        — химчистка дверей;<br/>
        — химчистка потолка;<br/>
        — чистка салона пылесосом.<br/>
        — чистка багажника.<br/>
        <div style={{marginTop:'20px',marginBottom:'10px'}}><Button type='primary'><NavLink to='/onlinez'>Записатся онлайн</NavLink></Button></div>

        </Card>
        <Card title='Цены Полировки'>
        Абразивная полировка автомобиля:<br/>
(1 и 2 категория) 4990 руб.<br/>
(3 и 4 категория)  6490 руб.<br/>
            — обработка всего кузова включая фаров
            Возможные доплаты на восстановительную полировку:<br/>
            — удаление смоляных пятен, если имеются (почки тополя, дорожный гудрон, битум) — от 500 до 1500 руб.;<br/>
            — ремонт глубоких царапин, удаление сколов — от 200 до 800 руб.<br/>
            <div style={{marginTop:'20px',marginBottom:'10px'}}><Button type='primary'><NavLink to='/onlinez'>Записатся онлайн</NavLink></Button></div>
        </Card>
            <Card title='Цены Керамика'>
                -Один слой керамики 8000 рублей для автомобилей средного класса,
                -Один слой керамики 10000 рублей для кроссоверов и джипов
            <div style={{marginTop:'20px',marginBottom:'10px'}}><Button type='primary'><NavLink to='/ceramica'>Подробно</NavLink></Button></div>
        </Card>
            <Card title='Цены Жидкое стекло'>
                -5000 рублей для автомобилей средного класса,
                -6000 рублей для кроссоверов и джипов
            <div style={{marginTop:'20px',marginBottom:'10px'}}><Button type='primary'><NavLink to='/zhidkoe'>Подробно</NavLink></Button></div>
                <Card title='Цены на стандартную мойку автомобиля '>
                    500рублей на стандартную мойку легкового автомобиля эконом-класса
                    600рублей на стандартную мойку легкового автомобиля «Гольф» или С-класса
                    700рублей на стандартную мойку легкового минивэна, бизнес-класса или паркетника
                    800 рублей на стандартную мойку внедорожника, легкового автомобиля представительского класса или микроавтобуса<br/>
                    В стоимость экспресс-мойку входит:<br/>
                    — мойка кузова автомобиля с пеной;<br/>
                    — мойка ковриков;<br/>
                    — сушка поверхности автомобиля.<br/>
                </Card>
                <Card title='Цены на Комплексную мойку автомобиля '>
                    800рублей на Комплексную мойку легкового автомобиля эконом-класса
                    950рублей на Комплексную мойку легкового автомобиля «Гольф» или С-класса
                    1100рублей на Комплексную мойку легкового минивэна, бизнес-класса или паркетника
                    1250 рублей на Комплексную мойку внедорожника, легкового автомобиля представительского класса или микроавтобуса<br/>
                    В стоимость комплексную мойку входит:<br/>
                    — мойка кузова автомобиля с пеной;<br/>
                    — мойка ковриков;<br/>
                    — мойка порогов;<br/>
                    — влажная уборка салона;<br/>
                    — уборка салона пылесосом;<br/>
                    — продувка замков и дверей;<br/>
                    — мойка внутренних проемов дверей;<br/>
                    — мойка торцов;<br/>
                    — сушка поверхности автомобиля;<br/>
                    — полироль пластмассы;<br/>
                    — влажная протирка пластиковых обивок дверей;<br/>
                    — протирка стекол изнутри с моющим средством;<br/>
                    — чернение резины.<br/>

                </Card>
        </Card>

            </>
    )
}
export default Price