import React, {useEffect} from "react";
import InstagramEmbed from "react-instagram-embed";
import s from './style.module.css'

const Ceramica =()=>{
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return(
        <div style={{margin:"0 7px"}}>
            <h2 style={{marginTop:"10px"}}>КЕРАМИЧЕСКОЕ ПОКРЫТИЕ АВТОМОБИЛЯ</h2>
            <div>Лакокрасочное покрытие автомобиля подвержено негативному воздействию внешней среды. Химические реагенты, песок, пыль, неквалифицированные мойщики, солнечные лучи существенно ухудшают внешний вид кузова автомобиля. Но каждый автолюбитель хочет, чтобы его авто даже через длительный период времени выглядел как из салона.  Вернуть первоначальный вид автомобилю можно с помощью полировки, но без покрытия кузова автомобиля керамическим составом, лакокрасочное покрытие будет неизбежно ухудшаться в ходе эксплуатации. Для надежной и длительной защиты кузова автомобиля, а также для придания ему глубокого насыщенного цвета, яркого блеска и отличного гидрофобного эффекта мы предлагаем нанесение только проверенных связок составов, отлично зарекомендовавших себя в процессе нашей работы по нанесению защитных покрытий.</div>
            <div className={s.insta}>
                <InstagramEmbed
                    url='https://www.instagram.com/p/CCJcVbTIMb7/?utm_source=ig_web_copy_link'
                    maxWidth={'768'}
                    hideCaption={false}
                    containerTagName='div'
                    protocol=''
                    injectScript
                    onLoading={() => {}}
                    onSuccess={() => {}}
                    onAfterRender={() => {}}
                    onFailure={() => {}}
                />
                <InstagramEmbed
                    url='https://www.instagram.com/p/CDBpp01Iqvf/?utm_source=ig_web_copy_link'
                    maxWidth={'768'}
                    hideCaption={false}
                    containerTagName='div'
                    protocol=''
                    injectScript
                    onLoading={() => {}}
                    onSuccess={() => {}}
                    onAfterRender={() => {}}
                    onFailure={() => {}}
                />
                <InstagramEmbed
                    url='https://www.instagram.com/p/CDSIf1zo6iP/?utm_source=ig_web_copy_link'
                    maxWidth={'768'}
                    hideCaption={false}
                    containerTagName='div'
                    protocol=''
                    injectScript
                    onLoading={() => {}}
                    onSuccess={() => {}}
                    onAfterRender={() => {}}
                    onFailure={() => {}}
                />
            </div>
        </div>
    )
}
export default Ceramica