import React, {useEffect, useState} from "react";
import {Spin} from "antd";


const Otziv = () =>{
    const [loading,setLoading]=useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(()=>{
        setTimeout(()=>{
            setLoading(false)
        },3000)
    },[])

    return (
        <>
        Страница разрабатывается....</>
        /*<>{loading&&<div style={{marginTop:"100px"}}><Spin size="large"/></div>}
        <div style={{display:"flex",justifyContent:"center",maxWidth:"1168px",width:"100%"}}>
            <div style={{maxWidth:'768px',width:"100%",height:'1200px',overflow:'hidden',position:'relative'}}><iframe style={{width:"100%",height:"100%",border:"1px solid #e6e6e6",borderRadius:"8px",boxSizing:"border-box" }} src="https://yandex.ru/maps-reviews-widget/76651404429?comments"></iframe><a href="https://yandex.ru/maps/org/shinomontazh/76651404429/" target="_blank" style={{boxSizing:"border-box",textDecoration:"none",color:"#b3b3b3",fontSize:"10px",fontFamily:"YS Text,sans-serif",padding:"0 20px",position:"absolute",bottom:"8px",width:"100%",textAlign:"center"}}>Шиномонтаж на карте Москвы — Яндекс.Карты</a></div>
        </div>
            </>*/
    )
}
export default Otziv