import React from "react";
import {NavLink} from "react-router-dom";

const Ffooter=()=>{
    return(
        <div className="row">
            <div className="col-md-3 col-sm-3">
                <div className="bottom-menu">
                    <div className="items">


                        <div className="wrap">
                            <div className="item-link">

                            </div>
                            <div className="item-link">

                            </div>
                            <div className="item-link">
                                <div className="item">
                                    <div className="title">
                                        <a href="/">Отзывы</a>
                                    </div>
                                </div>
                            </div>
                            <div className="item-link">
                                <div className="item">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="col-md-3 col-sm-3">


                <div className="bottom-menu">
                    <div className="items">
                        <div className="item-link">
                            <div className="item">
                                <div className="title">
                                    <NavLink to='/uslugi'>Услуги</NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="wrap">
                            <div className="item-link">
                                <div className="item">
                                    <div className="title">
                                        <NavLink to="/shino">Шиномонтаж</NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="item-link">
                                <div className="item">
                                    <div className="title">
                                        <NavLink to="/khim">Химчистка автомобиля</NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="item-link">
                                <div className="item">
                                    <div className="title">
                                        <NavLink to="/poly">Полировка автомобиля</NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="item-link">
                                <div className="item">
                                    <div className="title">
                                        <NavLink to='/poly'>Защитные покрытия</NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="item-link">
                                <div className="item">
                                    <div className="title">
                                        <NavLink to="/onlinez">Записаться Онлайн</NavLink>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default Ffooter