import React from "react";
import { Button, Input,Space,Form } from "antd";
import s from "./klient.module.css"
import Axios from "axios";
import addressAdd from "./addressAdd";

const priceAdd =()=>{
  const priceAddServer=(e)=>{
      Axios.post("https://rshakh.ru :3002/priceadd",{e}).then((data)=>{
          alert("Цена изменина")
      })
  }
    const priceAddServerkross=(e)=>{
        Axios.post("https://rshakh.ru :3002/priceaddkross",{e}).then((data)=>{
            alert("Цена изменина")
        })
    }
    
    const priceError=(e)=>{

    }
return (
    <div className={s.price}>
      <h3>Леговые авто</h3>
         <Form
         className={s.price_inner}
    name="basic"
    initialValues={{ remember: true }}
    onFinish={priceAddServer}
    onFinishFailed={priceError}
    autoComplete="off"
  >
  <Form.Item
  style={{padding:0}}
      label="radius"
      name="rad"
      rules={[{ required: true, message: 'Введите радиус' }]}
    >
      <Input  style={{width:"70%",margin:0}}/>
    </Form.Item>
    <Form.Item
      label="Цена"
      name="price"
      rules={[{ required: true, message: 'Введите цену' }]}

    >
        <Input style={{width:"70%",margin:0}} />
    </Form.Item>
    <Button style={{marginTop:"15px"}} type="primary" htmlType="submit">
        Добавить
      </Button>

  </Form>
  <h3>Кроссоверы</h3>
         <Form
         className={s.price_inner}
    name="basic"
    initialValues={{ remember: true }}
    onFinish={priceAddServerkross}
    onFinishFailed={priceError}
    autoComplete="off"
  >
  <Form.Item
  style={{padding:0}}
      label="radius"
      name="rad"
      rules={[{ required: true, message: 'Введите радиус' }]}
    >
      <Input  style={{width:"70%",margin:0}}/>
    </Form.Item>
    <Form.Item
      label="Цена"
      name="price"
      rules={[{ required: true, message: 'Введите цену' }]}

    >
        <Input style={{width:"70%",margin:0}} />
    </Form.Item>
    <Button style={{marginTop:"15px"}} type="primary" htmlType="submit">
        Добавить
      </Button>

  </Form>
  </div>
)

}

export default priceAdd