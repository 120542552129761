import React from "react";
import {YMaps, Map, Placemark} from 'react-yandex-maps';
import {Button, Card} from "antd";
import {NavLink} from "react-router-dom";
import s from "./style.module.css"

const YMap = () => {
    const mapData = {
        center: [55.751574, 37.573856],
        zoom: 9,
    };
    const coordinates = [
        [55.777793, 37.680445],
        [55.668635, 37.518758],
        [55.856477, 37.581541],
        [55.657768, 37.761438],
        [55.687766, 37.488125],
        [55.782856, 37.703586],
        [55.637410, 37.667846],
        [55.722953, 37.665650]

    ]
  return(  <YMaps>
        <Card title='Наши адреса' style={{marginTop:'0px'}} className="card">
            <div className={s.dis}>
                <div className={s.map}>
                    <Map defaultState={mapData}>
                        {coordinates.map(coordinate => <Placemark geometry={coordinate} />)}
                    </Map>
                </div>

                <div>
                    <h3>Мы Уже на рынке Москвы 5 лет ,мы ждем вас по адресам</h3>  <br/>

                    <div>
                        <div>метро Проспект Вернадского , метро Калужская</div>
                        <div><address><h3>Москва Ленинский проспект, 99</h3></address></div>
                    </div>
                    <div>
                        <div>метро Электрозоводская,</div>
                        <div><address><h3>Москва Большая Семёнская 11</h3></address></div>
                    </div>
                    <div>
                        <div>метро Варшавская,</div>
                        <div><address><h3>Варшавская шоссе 83А стр 1</h3></address></div>
                    </div>
                    <div>
                        <div>метро Беломорская,</div>
                        <div><address><h3>Беломорская улица, 40</h3></address></div>
                    </div>
                    <div>
                        <div>МЦК Коптево,</div>
                        <div><address><h3>Коптевская улица, 65Ас2</h3></address></div>
                    </div>
                    <div>
                        <div>метро Сокол,</div>
                        <div><address><h3>Ленинградский проспект, 70</h3></address></div>
                    </div>
                    <div>
                        <div>метро Раменки,</div>
                        <div><address><h3>Москва Удальцова 60</h3></address></div>
                    </div>
                    <div>
                        <div>метро Бауманская</div>
                        <div><address><h3>Москва, улица Новая Переведеновская 1 стр 1</h3></address></div>
                    </div>
                    <div>
                        <div>метро Отрадное</div>
                        <div><address><h3>Москва Олонецкая улица 21 </h3></address></div>
                    </div>
                    <div>
                        <div>метро Царицыно</div>
                        <div><address><h3>Москва Луганская улица 9 стр 6</h3></address></div>
                    </div>
                    <div>
                        <div>метро Кантемировская</div>
                        <div><address><h3>Москва Кантемировская улица, 29к1</h3></address></div>
                    </div>
                    <div>
                        <div>метро Коломенская</div>
                        <div><address><h3>Москва улица Садовники 11А</h3></address></div>
                    </div>
                    <div>
                        <div>район Брюлёво Западное</div>
                        <div><address><h3>Москва Медынская 5 к3</h3></address></div>
                    </div>
                    <div>
                        <div>метро Сходненская</div>
                        <div><address><h3>Москва проезд Донейлайтиса 12 к2</h3></address></div>
                    </div>
                    <div>
                        <div>метро Беговая</div>
                        <div><address><h3>Москва 1-й магистральный тупик 5</h3></address></div>
                        <div><a style={{textDecoration:"none"}} href={"tel:+79193867373"}>+79193867373</a> </div>
                    </div>
                    <div>
                        <div>метро Беговая</div>
                        <div><address><h3>Москва 3-я Хорошевская улица 21А</h3></address></div>
                        <div><a style={{textDecoration:"none"}} href={"tel:+79193867373"}>+79193867373</a> </div>
                    </div>
                    <div>
                        <div> Московская область</div>
                        <div><address><h3>Красногорск, Красногорский бульвар 48с1</h3></address></div>
                  </div>
                  <div>
                        <div>метро Бельяево</div>
                      <div><address><h3>Улица Академика Опарина, 7</h3></address></div>
                    </div>
                    <div>
                        <div>метро Дубровка, метро Пролетарская </div>
                        <div><address><h3>Шарикоподшипниковская улица, 1</h3></address></div>
                    </div>
                    <div>
                        <div>метро Красносельская </div>
                        <div><address><h3>Русаковская, 1</h3></address></div>
                    </div>
                    <div>
                        <div>метро Щёлковская </div>
                        <div><address><h3>Щёлковское шоссе, 61</h3></address></div>
                    </div>
                    <div>
                        <div>метро Римская </div>
                        <div><address><h3>Международная улица, 7</h3></address></div>
                    </div>
                    <div>
                        <div>метро Щукинская </div>
                        <div><address><h3>Врачебный проезд , 13к3 с3</h3></address></div>
                    </div>
                    <div>
                        <div>метро Каширская </div>
                        <div><address><h3>Каширское шоссе 19</h3></address></div>
                    </div>
                    <div>
                        <div>Москва</div>
                        <div><address><h3>Дмитровское шоссе 56 корпус 1 стр 2</h3></address></div>
                    </div>
                    <div>
                        <div>Москва</div>
                        <div><address><h3>Профсоюзная улица 118</h3></address></div>
                    </div>
                    <div>
                        <div>Москва</div>
                        <div><address><h3>Ильменский проезд 8 стр 3</h3></address></div>
                    </div>
                    <div>
                        <div>Москва</div>
                        <div><address><h3>Вернадский проспект 102А</h3></address></div>
                    </div>
                    
                    <Button><NavLink to='/contacts'>Создать Маршруть?</NavLink></Button>
                </div>


            </div>

        </Card>
    </YMaps>
  )
}

export default YMap