import React, {useEffect} from "react";
import s from "./style.module.css"
import {Button, Card} from "antd";
import shino from '../../media/img/schinomontazh.jpg'
import khim from '../../media/img/khim.jpg'
import poly from '../../media/img/poly.jpg'
import zhidkoe from '../../media/img/zhidkoe.jpg'
import ceramica from '../../media/img/ceramica.jpg'
import {NavLink} from "react-router-dom";
const Usligu =()=>{
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return(
        <Card title='Услуги' >
            <div className={s.uslugi}>
            <div className="site-card-border-less-wrapper card">
                <Card title="Шиномонтаж"  className="card">
                    <NavLink to='/shino'>
                        <div className={s.usluga}><img src={shino} alt="Шиномонтаж москва"/>
                            <div className={s.btn}><Button type="primary" block>
                                <NavLink to='/shino'>Подробнее</NavLink>
                            </Button></div></div>
                    </NavLink>

                </Card>
            </div>
            <div className="site-card-border-less-wrapper card">
                <Card  title="Химчистка"  className="card">
                    <NavLink to='/khim'>  <div className={s.usluga}><img src={khim} alt="Шиномонтаж москва"/>
                        <div className={s.btn}><Button type="primary" block>
                            <NavLink to='/khim'>Подробнее</NavLink>
                        </Button></div></div></NavLink>
                </Card>
            </div>
            <div className="site-card-border-less-wrapper card">
                <Card title="Полировка" className="card">
                    <NavLink to='/poly'><div className={s.usluga}><img src={poly} alt="Полировка москва"/>
                        <div className={s.btn}><Button type="primary" block>
                           <NavLink to='/poly'>Подробнее</NavLink>
                        </Button></div></div></NavLink>

                </Card>
            </div>
                <div className="site-card-border-less-wrapper card">
                <Card title="Жидкое стекло" className="card">
                    <NavLink to='/poly'><div className={s.usluga}><img src={zhidkoe} alt="Полировка москва"/>
                        <div className={s.btn}><Button type="primary" block>
                           <NavLink to='/zhidkoe'>Подробнее</NavLink>
                        </Button></div></div></NavLink>

                </Card>
            </div>
                <div className="site-card-border-less-wrapper card">
                <Card title="Керамика" className="card">
                    <NavLink to='/poly'><div className={s.usluga}><img src={ceramica} alt="Керамика москва"/>
                        <div className={s.btn}><Button type="primary" block>
                           <NavLink to='/ceramica'>Подробнее</NavLink>
                        </Button></div></div></NavLink>

                </Card>
            </div>
            </div>
        </Card>
    )
}
export default Usligu