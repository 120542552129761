import React from "react";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import khim1 from '../../media/img/khim/1.jpg'
import khim2 from '../../media/img/khim/2.jpg'
import khim3 from '../../media/img/khim/3.jpg'
import khim4 from '../../media/img/khim/4.jpg'
import s from './style.module.css'

const KhimGallery = () => {
    return(
        <div className={s.khiminner}>
            <h2>Химчистка Авто</h2>
            <AwesomeSlider className={s.khim}>
                <div data-src={khim1}/>
                <div data-src={khim2} />
                <div data-src={khim3} />
                <div data-src={khim4} />
            </AwesomeSlider>
        </div>
    )
}
export default KhimGallery