import React, {useEffect} from "react";
import s from "./style.module.css"
import InstagramEmbed from "react-instagram-embed";

const Zhidkoe = ()=>{
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return(
    <div className={s.text}>
        <h2>Жидкое стекло</h2>
        <div className={s.innertext}>
            Жидкое стекло для автомобиля получило в последние годы широкое распространение как один из самых современных материалов, используемых для защитного покрытия кузова. Применение жидкого стекла на практике доказало его эффективность: помимо улучшения эстетического восприятия авто, защитные свойства покрытия сохраняются на срок до 12 месяцев.

            Что же такое жидкое стекло для авто, из каких компонентов оно состоит? Почему оно придает автомобилю яркий внешний вид и от чего обеспечивает защиту? Какова стоимость материалов и нанесения покрытия жидким стеклом? Можно ли самостоятельно наносить жидкое стекло и что для этого требуется?

            Ответы на эти вопросы могут помочь автомобилистам, подбирающим защитное покрытие для своего авто, принять верное решение.
        </div>
        <h2>Жидкое стекло для автомобиля – что это такое и для чего оно нужно</h2>
        Кузов – самая заметная и дорогая часть автомобиля, поэтому средства ухода за ним и защиты от неблагоприятных воздействий востребованы всегда. Долгое время для кузова использовалась защитная полировка материалами на восковой основе, однако развитие технологий открыло возможность применения составов на основе соединений кремния.

        Собственно, в основе того, что обычно называют стеклом или керамикой, лежит обычный песок. Точнее не сам песок, конечно, а силикаты – солевые соединения кремния.

        ￼

        Щелочные растворы силикатов натрия или калия давно и хорошо известны и применяются в строительстве (гидроизоляция) и в быту (конторский клей). Кстати, иногда жидкое стекло производят и как стекло обычное: спеканием песка и, например, обычной соды при высокой температуре.

        Основными ингредиентами покрытия являются следующие компоненты:

        Добавки оксидов титана и алюминия по определённой технологии в материал, содержащий диоксид кремния (песок),
        Жидкость на силиконовой основе,
        Поверхностно активные вещества (ПАВ),
        Химические составы, используемые при производстве полимерных плёнок.
        Оксид титана создает тонкую плёнку для термозащиты материала на уровне нано-частиц, поэтому современное жидкое стекло на полном основании относят к нанокерамике. Именно это вещество придаёт покрытию глянцевый блеск.

        ￼

        Поверхностно-активные вещества используют как ингредиент, содержащийся в моющих средствах. ПАВ нужны для «отталкивания» оставшихся на подготовленной поверхности кузова грязевых частиц, которые могут помешать процессу формирования покрытия.

        Силиконовая основа служит для предотвращения окислительных процессов и улучшения адгезии («сцепления») покрытия с поверхностью кузова.

        ￼

        Остальные составляющие могут использоваться в различных пропорциях, но «песочная» основа жидкого стекла, улучшающая прочностные характеристики покрытия, остаётся неизменной.

        Что даёт для лакокрасочного покрытия автомобиля жидкое стекло, кроме придания блеска?
        В первую очередь – это более твердая, чем у обычных автомобильных лаков поверхность.

        Твердость, кстати, определяется так же, как у графитовых карандашей и имеет индекс «H».

        Однако следует помнить, что твердость покрытия будет в значительной степени зависеть от подготовки защищаемой поверхности:

        для новых автомобилей, покрытых «твердыми» производственными лаками, итоговая (после нанесения) твердость покрытия будет максимальной;
        для перекрашенных поверхностей твердость нанесенного покрытия будет ниже, чем для покрытий новых автомобилей из-за того, что ремонтные лаки, как правило, «мягкие»;
        обеспечить равномерную твёрдость для автомобилей со значительным пробегом вообще проблематично, так как помимо крашеных (да ещё, возможно, и в разное время) частей кузова может потребоваться различная (вплоть до абразивной) предварительная подготовка этих частей.
        Жидкое стекло создаёт покрытие, способное в большей степени, чем автомобильный лак предотвращать появление сколов, царапин, небольших вмятин не только за счёт повышенной твёрдости, но и за счет определённой пластичности. И, конечно же, такое покрытие – дополнительная защита от агрессивной среды.

        Часто в рекламных материалах, посвящённых жидкому стеклу можно встретить утверждение о том, что применение покрытия улучшает насыщенность цвета кузова и делает его более глубоким и ярким. Конечно, такого рода утверждения не более чем миф.

        ￼

        Даже если бы прозрачность покрытия была идеальной (светопропускание 100%, чего на самом деле не бывает), яркость и насыщенность исходного цвета удалось бы в лучшем случае сохранить. Другое дело, что компоненты покрытия значительно сокращают пагубное влияние на краску ультрафиолетового излучения, максимально долго сохраняя «родной» цвет.

        После правильного нанесения покрытия на автомобиль и после продолжительных поездок, машину достаточно ополоснуть чистой водой без применения моющих средств – блестящая поверхность кузова мгновенно восстанавливается.

        ￼
        Из недостатков покрытия жидким стеклом следует отметить разве что высокую итоговую (материалы плюс работа) стоимость процесса. При этом трудоёмкость процесса предварительной подготовки поверхности, особенно для машин с пробегом, действительно весьма велика.

        Долго ли держится покрытие автомобиля жидким стеклом?
        Ведущие мировые производители декларируют долговечность покрытия с сохранением основных свойств в течение двух-трёх лет. По отзывам российских автолюбителей, средняя долговечность такого покрытия на российских дорогах – 1 год.<br/>
        <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}>
            <InstagramEmbed
                url='https://www.instagram.com/p/CDwjz6BIr26/?utm_source=ig_web_copy_link'
                maxWidth={'768'}
                hideCaption={false}
                containerTagName='div'
                protocol=''
                injectScript
                onLoading={() => {}}
                onSuccess={() => {}}
                onAfterRender={() => {}}
                onFailure={() => {}}
            />
        </div>

        </div>
    )
}

export default Zhidkoe
