import { Form ,Input,Button} from "antd";
import React from "react";
import s from "./klient.module.css"

const addressAdd =()=>{
    const addressSend=(e)=>{
        console.log(e)
    }

    return (
        <div className={s.prace}>
            <Form className={s.add} onFinish={addressSend}>
                <Form.Item
                className={s.add__inner}
                style={{width:"20%"}}
                name="sity"
                label="город"
                >
                    <Input className={s.add__inner}/>
                </Form.Item>
                <Form.Item
                 
                style={{width:"40%"}}
                name="street"
                label="улица"
                >
                    <Input className={s.add__inner} />
                </Form.Item>
                <Form.Item
                 className={s.add__inner}
                style={{width:"20%"}}
                name="house"
                label="дом"
                >
                    <Input className={s.add__inner}/>
                </Form.Item>
                <Button style={{marginBottom:"15px"}} type="primary" htmlType="submit">
        Добавить
      </Button>
            </Form>
            <Form className={s.add} onFinish={addressSend}>
                <Form.Item
                className={s.add__inner}
                style={{width:"20%"}}
                name="sity"
                label="город"
                >
                    <Input className={s.add__inner}/>
                </Form.Item>
                <Form.Item
                 
                style={{width:"40%"}}
                name="street"
                label="улица"
                >
                    <Input className={s.add__inner} />
                </Form.Item>
                <Form.Item
                 className={s.add__inner}
                style={{width:"20%"}}
                name="house"
                label="дом"
                >
                    <Input className={s.add__inner}/>
                </Form.Item>
                <Button style={{marginBottom:"15px"}} type="primary" htmlType="submit">
        Удалить
      </Button>
            </Form>
        </div>
    )
}

export default addressAdd