import React, {Suspense, useEffect, useState,} from 'react'
import './App.css'
import {Button, Card, Layout, Menu, Spin} from "antd"
import Usligu from "./Components/Uslugi/Uslugi";
import Ffooter from "./Components/Footer/FFooter";
import Khimchistka from "./Components/Khimchistka/Khimchistka";
import {NavLink, Route, Router, withRouter} from "react-router-dom";
import Glavnoe from "./Components/Content/Glavnoe";
import Shino from "./Components/Shinomontazhe/Shinomontazhe";
import Poly from "./Components/Polyrovka/Polyrovka";
import Price from "./Components/Price/Price";
import BMenu from "./Components/Menu/Menu";
import YMap from "./Components/Map/Map";
import Contacts from "./Components/Contacts/Contacts";
import OnlineZ from "./OnlineZapis/OnlineZ";
import logo from "./media/img/logo.png"
import Klient from "./Components/klient/Klient";
import Ymaps from "./Components/Ymaps/Ymaps";
import Nav from "./Components/Header/Nav";
import Otziv from "./Components/Otziv/Otziv";
import AnimCar from "./Components/Animation/Animation";
import Skidki from "./Components/skidki/skidki";
import Logo from "./Components/Logo/Logo";
//import Sales from "./Components/skidki/sales";
import Partners from "./Components/Partners/Partners";
import Zhidkoe from "./Components/zhidkoe/Zhidkoe";
import Ceramica from "./Components/Ceramica/Ceramica";
import priceAdd from './Components/klient/priceAdd';
import addressAdd from './Components/klient/addressAdd';
const Gallery = React.lazy(() => import('./Components/Gallery/Gallery'));
const {Footer,Header} = Layout;


function App(props) {
    const [key,setKeys]=useState('1')
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const handleClick=(e)=>{
        setKeys(e.key)
    }
  return (
      <Layout className='App'>
          <Header className='head'>
              <div><NavLink to='/'><Logo/></NavLink></div>
              <NavLink to="/onlinez"><Button className='knopka' type="primary">Онлайн запись</Button></NavLink>
              <Menu onSelect={handleClick} openKeys={[key]} className='komp' theme="dark" mode="horizontal" defaultSelectedKeys={[key]}>
                  <Menu.Item key="1"><NavLink to='/'>Главная</NavLink></Menu.Item>
                  <Menu.Item key="2"><NavLink to='/price'>Цены</NavLink></Menu.Item>
                  <Menu.Item key="3"><NavLink to='/contacts'>Контакты и Адреса</NavLink></Menu.Item>
                  <Menu.Item key="4"><NavLink to='/uslugi'>Услуги</NavLink></Menu.Item>
                  <Menu.Item key="5"><NavLink to='/onlinez'>Запись Онлайн</NavLink></Menu.Item>
                  <Menu.Item key="6"><NavLink to='/Gallery'>Фото Галерея</NavLink></Menu.Item>
                  <Menu.Item key="7"><NavLink to='/otziv'>Отзывы</NavLink></Menu.Item>
              </Menu>
              <div className='mobila'><BMenu/></div>
          </Header>
          <Route exact path='/' component={()=><div className='animated bounceInLeft faster' style={{marginTop:'20px'}}><Glavnoe/></div>}/>
          <Route exact path='/onlinez' component={()=><div className='animated bounceInLeft faster'><OnlineZ/></div>}/>
          <Route path='/khim' render={()=><div className='animated bounceInLeft faster'><Khimchistka/></div>}/>
          <Route path='/uslugi' render={()=><div className='animated bounceInLeft faster'><Usligu/></div>}/>
          <Route path='/shino' render={()=><div className='animated bounceInLeft faster'><Shino/></div>}/>
          <Route path='/poly' render={()=><div className='animated bounceInLeft faster'><Poly/></div>}/>
          <Route path='/price' render={()=><div className='animated bounceInLeft faster'><Price/></div>}/>
          <Route path='/klient' render={()=><div className='animated bounceInLeft faster'><Klient/></div>}/>
          <Route path='/otziv' render={()=><div className='animated bounceInLeft faster'><Otziv/></div>}/>
          <Route path='/zhidkoe' render={()=><div className='animated bounceInLeft faster'><Zhidkoe/></div>}/>
          <Route path='/ceramica' render={()=><div className='animated bounceInLeft faster'><Ceramica/></div>}/>
          <Route path='/Gallery' render={()=>{
              return(
                  <Suspense fallback={<div style={{marginTop:'45%'}}><Spin size="large"/></div>}>
                      <div className='animated bounceInLeft faster'><Gallery/></div>
                  </Suspense>
              )
          }}/>
          <Route path="/editprice" render={priceAdd}/>
          <Route path="/editaddress" render={addressAdd}/>
          <Route path='/contacts' render={()=>{
              return(
                  <>
                      <Ymaps/>
                      <Usligu/>
                  </>)
          }}/>
          {props.location.pathname ==='/app/contacts' || props.location.pathname ==='/app/klient' ?<></>:
          <>
              <Partners/>
              <Contacts/>
              <YMap/>
              </>}

          <Footer className='footer'>Автостол.рф ©2021 Created by <a href="mailto:ushajindi@gmail.com">RadzabovSS</a>
              <div style={{display:"flex",justifyContent:"center"}}><NavLink to={'/'}><Logo/></NavLink></div>
              <div><NavLink to={'/klient'}>Служебно</NavLink></div>
          <Ffooter/></Footer>
      </Layout>
  )
}

export default withRouter(App)
//<Route path='/sales' render={()=><div className='animated bounceInLeft faster'><Sales/></div>}/>