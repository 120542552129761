import React from "react";
import koch from "../../media/img/koch.jpg"
import kry from "../../media/img/Krytex.jpg"
import ceramic from "../../media/img/ceramic.jpg"
import mmm from "../../media/img/3m.png"
import s from "./style.module.css"

const Partners =() => {
    return(
        <div className={s.partners}>
            <h2>Наши партнёры</h2>
            <div className={s.partnersico}>
                <img src={koch} alt=""/>
                <img src={kry} alt=""/>
                <img src={ceramic} alt=""/>
                <img src={mmm} alt=""/>
            </div>
            </div>
    )
}

export default Partners