import React, {useEffect} from "react";
import {Avatar, Card} from "antd";
import PhoneOutlined from "@ant-design/icons/lib/icons/PhoneOutlined";
import MailOutlined from "@ant-design/icons/lib/icons/MailOutlined";
import FieldTimeOutlined from "@ant-design/icons/lib/icons/FieldTimeOutlined";
import s from "./style.module.css"
import vk from './../../media/img/vk.png'
import ins from './../../media/img/instagram.png'
import fb from './../../media/img/fb.png'

const Contacts=()=>{
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return(
        <div className={s.contact}>
            <Card title='Контакты' style={{width:'100%'}}>
                <h3>В данный момент мы работаем по ниже указанным адресам, приезжайте, мы ждём вас!</h3>
                <div><PhoneOutlined /><a href='tel:8495 7446831'> 8 495 7446831</a></div>
                <div><PhoneOutlined /><a href='tel:+7 (926) 544-73-01'>+7 (926) 544-73-01</a></div>
                <div><PhoneOutlined /><a href='tel:+7 (968) 417-36-34'>+7 (968) 417-36-34</a></div>
                <div><MailOutlined /><a href='mailto:ushajindi@gmail.com'> ushajindi@gmail.com</a></div>
                <div className={s.rab}><FieldTimeOutlined />
                <h4>Режим работы 24 часа без выходных</h4>
                </div>
                <div className={s.social}>
                    <a href="https://vk.com/sgruop"><img style={{width:'35px',height:'35px'}} src={vk}/></a>
                    <a style={{marginLeft:'10px',marginRight:'10px'}} href="https://www.instagram.com/avto.stol/"><img style={{width:'35px',height:'35px'}} src={ins}/></a>
                    <a href="https://www.facebook.com/avtostol"><img style={{width:'35px',height:'35px'}} src={fb}/></a>
                </div>
            </Card>
        </div>
    )
}
export default Contacts