import React, {useEffect, useState} from "react";
import {Card, Spin} from "antd";
import Contacts from "../Contacts/Contacts";
import s from './style.module.css'


const Ymaps =()=>{
    const [loading,setLoading]=useState(true)
    useEffect(()=>{
        setTimeout(()=>{
            setLoading(false)
        },8000)
    },[])
    return(
        <div className='animated bounceInLeft faster'><Contacts/><div className={s.map}>
            {loading && <div style={{position:'absolute',top:"0",background:"rgba(250, 248, 248, 0.8)",paddingBottom:'800px',width:'100%',maxWidth:'1168px'}}><div style={{padding:'150px'}}>
                <Spin size='large'/></div></div>}
            <div className='mapapp'>
                <div>
                    <div>метро Бауманская</div>
                    <div><address><h3>Москва, улица Новая Переведеновская 1 стр 1</h3></address></div>
                </div>
                <div>
                    <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=76651404429"
                            frameBorder="0"></iframe></div>
                <div>
                    <iframe src="https://yandex.ru/sprav/widget/rating-badge/76651404429" width="150" height="50"
                            frameBorder="0"></iframe></div>
            </div>
            <div className='mapapp'>
                <div>
                    <div>метро Проспект Вернадского , метро Калужская</div>
                    <div><address><h3>Москва Ленинский проспект, 99</h3></address></div>
                </div>
                <div>
                    <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=230000305321"
                            frameBorder="0"></iframe>
                </div>
                <div>
                    <iframe src="https://yandex.ru/sprav/widget/rating-badge/230000305321" width="150" height="50"
                            frameBorder="0"></iframe>
                </div>
            </div>
            <div className='mapapp'>
                <div>
                    <div>метро Владыкино</div>
                    <div><address><h3>Москва Алтуфьевское шоссе 27Б с2 </h3></address></div>
                </div>
                <div>
                    <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=175923903252"
                            frameBorder="0"></iframe>
                </div>
                <div>
                    <iframe src="https://yandex.ru/sprav/widget/rating-badge/175923903252" width="150" height="50"
                            frameBorder="0"></iframe>
                </div>
            </div>
            <div className='mapapp'>
                <div>
                    <div>метро Братиславская</div>
                    <div><address><h3>Москва Братиславская улица, 19к1</h3></address></div>
                </div>
                <div>
                    <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=56631174216"
                            frameBorder="0"></iframe>
                </div>
                <div>
                    <iframe src="https://yandex.ru/sprav/widget/rating-badge/56631174216" width="150" height="50"
                            frameBorder="0"></iframe>
                </div>
            </div>
        </div>

        </div>
    )
}
export default Ymaps