import React from "react";
import logo from "../../media/img/logo2.png"
import s from "./Logo.module.css"

const Logo = ()=>{
    return(
        <div className={s.logo}>
            <div className={s.logo__img}><img src={logo} alt=""/></div>
           <div className={s.logo__text}>Автостол.рф</div>
        </div>
    )
}
export default Logo